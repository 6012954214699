import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Store from './store'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import Container from '../components/container'
import GoogleMap from '../components/map'
import Key from '../components/key'
import SEO from '../components/seo'
import IndexPage from './index'
import AboutPage from './about'
import HistoryIndex from './history/index'
import HistoryView from './history/view'
import EventsIndex from './events/index'
import EventView from './events/view'

const App = () => {

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) setInitialized(true)
  }, []);

  return initialized && (
    <Store>
      <Layout>
        <Router>
          <Container>
            <SEO />
            <Route path="/" exact component={IndexPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/history" exact component={HistoryIndex} />
            <Route path="/history/:slug" exact component={HistoryView} />
            <Route path="/events" exact component={EventsIndex} />
            <Route path="/events/:slug" exact component={EventView} />
          </Container>
          <GoogleMap />
          <Key />
          <Navigation />
        </Router>
      </Layout>
    </Store>
  )
}

export default App
