import React, { useContext } from "react"
import { withRouter, matchPath } from 'react-router-dom'
import {
  ThemeContext,
  DataContext,
  MapContext,
  UIContext
} from "../app/store"

// Testing
// TODO: icon svgs need to come from contentful probably...
// import testHistoryIcon from "../images/icon-2.svg"
import testEventsIcon from "../images/icon-3.svg"

const Wrapper = (props) => {
  const [theme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  // eslint-disable-next-line
  const [map, setMap] = useContext(MapContext)
  const [UI, setUI] = useContext(UIContext)

  const closeSidebar = () => {
    console.log('close!')
    if (UI.isSideOpen) {
      setUI({
        isSideOpen: false
      })
    }
  }

  const openSidebar = () => {
    console.log('open!')
    if (!UI.isSideOpen) {
      setUI({
        isSideOpen: true
      })
    }
  }

  return (
    <GoogleMap
      theme={theme}
      data={data}
      setMap={setMap}
      closeSidebar={closeSidebar}
      openSidebar={openSidebar}
      {...props}
    />
  )
}

const defaultCenter = { lat: 51.53641910591854, lng: -0.04563527987136329 }

class GoogleMap extends React.Component {

  constructor(props) {
    super(props)
    this.mapEl = React.createRef()
    this.googleMarkerObjects = []
    this.activeTrailMarkers = []
    this.arrowSymbol = null
    this.google = window.google

    this.state = {
      currentPage: null
    }
  }

  componentDidMount() {
    this.initMap()
    this.checkRoute()
  }

  componentDidUpdate(prevProps) {
    this.updateMapColor()
    this.handleRouteChange()
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkRoute()
    }
  }

  checkRoute() {
    const isPage = matchPath(this.props.location.pathname, {
      path: "/:namespace/:slug",
      exact: false,
      strict: false
    });
    // const isIndex = matchPath(this.props.location.pathname, {
    //   path: "/:namespace",
    //   exact: false,
    //   strict: false
    // });
    if (isPage) {
      this.setActiveMarker(isPage.params.slug)
    }
  }

  initMap() {

    // NOTE: Init the map object
    this.googleMap = new this.google.maps.Map(this.mapEl.current, {
      zoom: 11,
      center: { lat: 51.53641910591854, lng: -0.04563527987136329 },
      styles: this.props.theme.mapStyle,
      disableDefaultUI: true
    })

    // NOTE: Set global ref in store
    this.props.setMap(this.googleMap)

    // NOTE: Add markers
    this.updateMarkersOnMap()

    // NOTE: UI addListeners
    this.addListeners()
  }

  updateMarkersOnMap() {

    const { allContentfulEvent, allContentfulPlace } = this.props.data
    const isEvents = this.props.location.pathname.includes('events');
    const isHistory = this.props.location.pathname.includes('history');

    // TODO: this will be determined by route from store probably...
    let markers
    // console.log(this.props.location.pathname)
    if (isEvents) {
      markers = allContentfulEvent.edges
    } else if (isHistory) {
      markers = allContentfulPlace.edges
    } else {
      markers = [
        ...allContentfulEvent.edges,
        ...allContentfulPlace.edges
      ]
    }

    markers.forEach(({ node }) => {
      const coords = node.locations ? node.locations[0].coordinates : node.location.coordinates

      // TODO: clean this up
      let icon;
      if (this.state.currentPage === node.slug) {
        icon = node.category ? node.category.mapIconActive ? node.category.mapIconActive.file.url : node.category.icon.file.url : testEventsIcon
      } else {
        icon = node.category ? node.category.mapIcon ? node.category.mapIcon.file.url : node.category.icon.file.url : testEventsIcon
      }

      this.addMarkerToMap(node, coords, icon)
    })
  }

  addMarkerToMap(node, coords, icon) {

    if (!coords) {
      return false
    }

    const { title, slug, locations } = node

    let marker = new this.google.maps.Marker({
      title: title,
      map: this.googleMap,
      position: {lat: coords.lat, lng: coords.lon},
      icon: icon
    })
    marker.addListener('click', () => this.handleMarkerClick(slug, coords, locations))
    this.googleMarkerObjects.push(marker)
  }

  addTrailToMap(node) {

    const waypoints = node.locations.map(({ coordinates }, i) => {
      return {
        location: {lat: coordinates.lat, lng: coordinates.lon},
        stopover: true,
      }
    })

    const directionsService = new this.google.maps.DirectionsService()
    this.directionsDisplay = new this.google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#000'
      }
    })

    directionsService.route({
      origin: waypoints[0].location,
      destination: waypoints[waypoints.length - 1].location,
      travelMode: 'WALKING',
      waypoints: waypoints
    }, (response, status) => {
      if (status === 'OK') {
        this.directionsDisplay.setMap(this.googleMap)
        this.directionsDisplay.setDirections(response)
      }
    })

    if (node.markers) {
      node.markers.forEach(marker => {
        const trailMarker = new this.google.maps.Marker({
          title: marker.title,
          map: this.googleMap,
          position: {lat: marker.coordinates.lat, lng: marker.coordinates.lon},
          icon: 'https://images.ctfassets.net/xt07s3glgn7o/4oBGnd6fEF1R4i8bdSlXG9/ba9f120479251316e3c7d0cc21cc742e/alt-icon-1-no-border.svg'
        })
        this.activeTrailMarkers.push(trailMarker)
      })
    }
  }

  handleMarkerClick = (slug, coords, locations) => {

    this.props.openSidebar()

    // NOTE: This is like clicking on a <Link />
    // Change page/route... (from app.js)
    // NOTE: Places (in history) only have 1 location — Events have multiple *locations*
    if (locations) {
      this.navigate(`/events/`, slug)
    } else {
      this.navigate(`/history/`, slug)
    }
  }

  navigate(to, slug) {
    this.props.history.push({
      pathname: `${to}${slug}`,
      params: {
        slug: slug
      }
    })
  }

  setActiveMarker(slug) {

    this.setState({
      currentPage: slug
    })

    // NOTE: Finds active page from router params and data store
    const activeSlug = slug;
    if (!activeSlug) {
      this.positionMap(defaultCenter, 11)
      console.log('no slug')
      return false;
    }

    const allMarkers = [
      ...this.props.data.allContentfulEvent.edges,
      ...this.props.data.allContentfulPlace.edges
    ]; // Creates new array of both to sort through
    const activePage = allMarkers.find(({node}) => node.slug === activeSlug); // Finds the content
    const { locations, location } = activePage.node; // Find the nodes
    const coords = locations ? locations[0].coordinates : location.coordinates; // Define the lon/lat

    // NOTE: If there are multiple locations, add the trail
    if (activePage.node.locations && locations.length > 1) {
      this.addTrailToMap(activePage.node)
    }

    // NOTE: Set position
    if (coords) {
      this.positionMap({ lat: coords.lat, lng: coords.lon })
    }
  }

  positionMap(coords, zoom = 13) {

    this.googleMap.setOptions({
      zoom: zoom,
      center: coords
    })
  }

  // setMapOnAll(map) {
  //   for (var i = 0; i < this.googleMarkerObjects.length; i++) {
  //     this.googleMarkerObjects[i].setMap(map);
  //   }
  // }
  // removeMarkers(markers) {
  //   markers.forEach(marker => marker.setMap(null))
  // }

  addListeners() {
    // console.log('hello')
    // this.googleMap.addListener('touchstart', () => {
    //   this.props.setUI({
    //     isSideOpen: false
    //   })
    // });
  }

  getCenter() {
    const { lat, lng } = this.googleMap.center
    console.log({lat: lat(), lng: lng()})
  }

  clearMarkers() {
    // Regular markers
    this.googleMarkerObjects.forEach(marker => marker.setMap(null))
    this.googleMarkerObjects = []
    // Trail markers (if exists)
    this.activeTrailMarkers.forEach(marker => marker.setMap(null))
    this.activeTrailMarkers = []
  }

  handleRouteChange() {
    // NOTE: Resets any active directions
    if (this.directionsDisplay) {
      this.directionsDisplay.setMap(null)

    }
    this.clearMarkers()
    this.updateMarkersOnMap()
  }

  updateMapColor() {
    this.googleMap.setOptions({
      styles: this.props.theme.mapStyle
    })
  }

  closeSidebar = () => {
    this.props.closeSidebar()
  }

  handleTouchStart = () => {
    this.props.closeSidebar()
  }

  render() {

    const { theme } = this.props

    return (
      <div
        id="map"
        ref={this.mapEl}
        style={{
          backgroundColor: theme.dark
        }}
        onTouchStart={this.handleTouchStart}
      />
    )
  }
}

export default withRouter(Wrapper)
