import React, { useContext, useState } from 'react'
import { ThemeContext } from '../app/store'
import { Link } from "react-router-dom"
import Hyperlink from './href'

const Navigation = () => {

  const [theme] = useContext(ThemeContext)
  const [menuOpen, setMenuOpen] = useState(false)

  // console.log('render nav')

  return (
    <nav className="pfix b0 w100 innerx2 gutters" style={{
      // backgroundColor: theme.medium,
      backgroundColor: theme.light,
      transition: 'background 0.2s'
    }}>
      <div className="prel mq-tablet-hide w100 flex-spaced" style={{
        height: menuOpen ? '33vh' : '1.2rem',
        transition: 'height 0.6s'
      }}>
        <div className="main-nav prel" style={{
          zIndex: 5
        }}>
          <Link to="/" className="h3">Sites of Significance: Madge Gill</Link>
        </div>
        <div className="mobile-menu flex-center" style={{
          height: '33vh',
          opacity: menuOpen ? 1 : 0,
          transition: 'opacity 0.3s',
          zIndex: 1
        }}>
          <center>
            <Link to="/history" className="mobile-link" onClick={e => setMenuOpen(false)}>History</Link>
            <Link to="/events" className="mobile-link" onClick={e => setMenuOpen(false)}>Events</Link>
            <Link to="/about" className="mobile-link" onClick={e => setMenuOpen(false)}>About</Link>
            <div className="outer">A project by:</div>
            <Hyperlink href="https://worksby-madgegill.co" className="link">Works by Madge Gill</Hyperlink>
          </center>
        </div>
        <div style={{
          zIndex: 6
        }}>
          <button className="btn small" onClick={e => setMenuOpen(!menuOpen)}>{menuOpen ? `x Close` : `Menu`}</button>
        </div>
      </div>
      <div className="mq-tablet-show w100 flex-spaced">
        <div className="main-nav">
          <Link to="/" className="h3">Sites of Significance: Madge Gill</Link>
          <Link to="/history">History</Link>
          <Link to="/events">Events</Link>
          <Link to="/about">About</Link>
        </div>
        <div className="gutters">
          A project by <Hyperlink href="https://worksby-madgegill.co" className="link">Works by Madge Gill</Hyperlink>
        </div>
      </div>

    </nav>
  )
}

export default Navigation
