import React, { useContext, useEffect } from 'react'
import { ThemeContext, DataContext, UIContext } from './store'
import colourScheme from '../theme/colour'

const IndexPage = (props) => {

  // eslint-disable-next-line
  const [UI, setUI] = useContext(UIContext)
  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  // eslint-disable-next-line
  const [data] = useContext(DataContext)

  useEffect(() => {
    setTheme(colourScheme.green)

    if (UI.isSideOpen) {
      setUI({
        ...UI,
        isSideOpen: false
      })
    }
  }); // Might need ...}, []);

  return <div />
}

export default IndexPage
