import React, { useContext, useEffect } from 'react'
import { ThemeContext, DataContext, UIContext } from './store'
import colourScheme from '../theme/colour'
import Markdown from '../components/markdown'

const AboutPage = ({ match }) => {

  // eslint-disable-next-line
  const [UI, setUI] = useContext(UIContext)
  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  // eslint-disable-next-line
  const [data] = useContext(DataContext)

  useEffect(() => {
    setTheme(colourScheme.green)

    if (!UI.isSideOpen) {
      setUI({
        ...UI,
        isSideOpen: true
      })
    }
  }, [match]); // Might need ...}, []);

  const { about } = data.allContentfulSettings.edges[0].node

  return (
    <div className="padx2">
      <Markdown className="md" field={about} />
    </div>
  )
}

export default AboutPage
