import React, { useContext, useEffect } from 'react'
import Img from "gatsby-image"
import { ThemeContext, DataContext, UIContext } from '../app/store'
import colourScheme from '../theme/colour'
import { Link } from "react-router-dom"
import Markdown from '../components/markdown'
// import Picture from '../components/picture'
import LinkOut from '../components/href'

class Calendar extends React.PureComponent {

  constructor(props) {
    super(props);
    this.wrapperEl = React.createRef();
    this.activeEl = React.createRef();
  }

  componentDidMount() {
    if (this.props.activeSlug) {
      this.scrollToPlace()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeSlug !== prevProps.activeSlug) {
      this.scrollToPlace()
    }
  }

  scrollToPlace() {
    this.wrapperEl.current.scrollTo(0, this.activeEl.current.offsetTop)
  }

  render() {

    const { data, activeSlug, isIndex } = this.props

    return (
      <div className="scroll-container" ref={this.wrapperEl}>
        {data.allContentfulEvent.edges.map(({node}) => {
          const isActive = isIndex || activeSlug === node.slug
          return (
            <div
              className={`place innerx2 ${isActive ? 'active' : ''}`}
              key={`event-index-${node.slug}`}
              ref={isActive ? this.activeEl : null}
            >
              {/* Category icon */}
              <img src={node.category.icon.file.url} className="icon mb" alt={`Icon for category ${node.category.title}`} />

              <Link
                className="pabs r0 innerx2 link"
                to={{
                  pathname: `/events/${node.slug}`,
                  params: {
                    slug: node.slug
                  }
                }}
              >
                Find on map
              </Link>

              {/* Heading/title */}
              <Markdown className="md" field={node.heading} />

              {/* Image */}
              {node.image && (
                <figure className="outer mbx2">
                  {/* <Picture {...node.image.fluid} /> */}
                  <Img fluid={node.image.fluid} draggable={false} />
                  {node.image.description && (
                    <figcaption className="p1 outer align-center">{node.image.description}</figcaption>
                  )}
                </figure>
              )}

              {/* Further description/details */}
              <Markdown className="md" field={node.description} />

              {/* Exeternal links TODO: More info/body node? */}
              <div className="outerx2">
                {node.externalLinkUrl && (
                  <LinkOut href={node.externalLinkUrl} className="btn mr">{node.externalLinkLabel || 'Find Tickets'}</LinkOut>
                )}
              </div>

            </div>
          )
        })}
      </div>
    )
  }
}

const Wrapper = ({ match, index }) => {

  // eslint-disable-next-line
  const [UI, setUI] = useContext(UIContext)
  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)

  useEffect(() => {
    if (theme !== colourScheme.blue) {
      setTheme(colourScheme.blue)
    }

    if (!UI.isSideOpen) {
      setUI({
        ...UI,
        isSideOpen: true
      })
    }
  }, [match]);

  return (
    <Calendar
      data={data}
      activeSlug={match.params.slug}
      isIndex={index ? true : false}
    />
  )
}

export default Wrapper
