import React, { useContext } from 'react'
import { DataContext, MapContext } from '../app/store'

const Key = () => {

  const [{ allContentfulCategory, allContentfulSettings }] = useContext(DataContext);
  const { shortcuts } = allContentfulSettings.edges[0].node;
  const [map] = useContext(MapContext);

  const handleShortcut = ({ coordinates }) => {
    map.setOptions({
      zoom: 9,
      center: {lat: coordinates.lat, lng: coordinates.lon}
    })
  }

  return (
    <div className="map-key pfix b0 r0 hide-when-open">

      <div className="bg-white outer pad categories">
        <h3 className="mb">Key:</h3>
        {allContentfulCategory.edges.map(({node}) => (
          <Category title={node.title} icon={node.icon.file.url} key={node.id} />
        ))}
      </div>
      <div className="bg-white outer pad shortcuts">
        <h3 className="mb">Shorcuts:</h3>
        {shortcuts.map((node) => {
          return (
            <button onClick={e => handleShortcut(node)} key={node.id}>{node.address.address}</button>
          )
        })}
      </div>
    </div>
  )
}

const Category = ({ icon, title }) => (
  <div className="key-category">
    <span className="icon">
      <img src={icon} alt="" />
    </span>
    <span>
      {title}
    </span>
  </div>
)

export default Key
