import React, { useContext, useEffect } from 'react'
import Img from "gatsby-image"
import { ThemeContext, DataContext, UIContext } from '../app/store'
import colourScheme from '../theme/colour'
import { Link } from "react-router-dom"
import Markdown from '../components/markdown'
// import Picture from '../components/picture'

class Chronology extends React.PureComponent {

  constructor(props) {
    super(props);
    this.wrapperEl = React.createRef();
    this.activeEl = React.createRef();
    this.state = {
      scrollTop: 0
    }
  }

  componentDidMount() {
    if (this.props.activeSlug) {
      this.scrollToPlace()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeSlug !== prevProps.activeSlug) {
      this.scrollToPlace()
    }
  }

  scrollToPlace() {
    this.wrapperEl.current.scrollTo(0, this.activeEl.current.offsetTop)
  }

  // onScroll = e => {
  //   this.setState({
  //     scrollTop: e.target.scrollTop
  //   })
  // }

  render() {

    const { data, activeSlug, isIndex } = this.props

    return (
      <div className="scroll-container" ref={this.wrapperEl}>
        {data.allContentfulPlace.edges.map(({node}) => {
          const isActive = isIndex || activeSlug === node.slug
          return (
            <div
              className={`place innerx2 ${isActive ? 'active' : ''}`}
              key={`history-index-${node.slug}`}
              ref={isActive ? this.activeEl : null}
            >
              <Link to={{
                pathname: `/history/${node.slug}`,
                params: {
                  slug: node.slug
                }
              }} className="pabs r0 innerx2 link">Find on map</Link>
              {node.category.icon && (
                <img src={node.category.icon.file.url} className="icon mb" alt={`Icon for category ${node.category.title}`} />
              )}
              <Markdown className="md" field={node.heading} />
              {node.image && (
                <figure className="outerx4">
                  <Img fluid={node.image.fluid} />
                  {/* <Picture {...node.image.fluid} /> */}
                  {node.image.description && (
                    <figcaption className="p1 outer align-center">{node.image.description}</figcaption>
                  )}
                </figure>
              )}
              <ul className="moments">
                {node.moments && node.moments.map((node) => {
                  return (
                    <li key={node.id}>
                      <h1>{node.date}</h1>
                      <Markdown className="md" field={node.body} />
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    )
  }
}

const Wrapper = ({ match, index }) => {

  // eslint-disable-next-line
  const [UI, setUI] = useContext(UIContext)
  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)

  useEffect(() => {
    if (theme !== colourScheme.red) {
      setTheme(colourScheme.red)
    }

    if (!UI.isSideOpen) {
      setUI({
        ...UI,
        isSideOpen: true
      })
    }
  }, [match]);

  return (
    <Chronology data={data} activeSlug={match.params.slug} isIndex={index ? true : false} />
  )
}

export default Wrapper
